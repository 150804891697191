<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        How many moles of water are present in a
        <number-value :value="mass" unit="\text{g}" />
        block of ice?
      </p>
      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{Water: }$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemSIU131Question7',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
